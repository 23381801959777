import React, { Component } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";

import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";

class Page404 extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`Page not found | ${config.siteTitle}`} />
          <p>Uh oh...</p>
          <h1>Page Not Found</h1>
          <p>But here's a pic of my cat, Miso!</p>
          <Img
            style={{ maxWidth: 500 }}
            fluid={this.props.data.file.childImageSharp.fluid}
            alt={this.props.data.file.childImageSharp.id}
          />
        </div>
      </Layout>
    );
  }
}

export default Page404;

export const query = graphql`
  query MisoQuery {
    file(relativePath: { regex: "/miso_rilakkuma/" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
